<script>
import NavigationBar from "./NavigationBar.vue";
export default {
  components: {
    NavigationBar,
  },
};
</script>

<template>
  <NavigationBar />

  <div class="img-bg flex justify-center items-center w-[100%] lg:h-[407px] h-[384px]">
    <div class="grid content mt-[94px] text-center gap-[24px]">
      <h1 class="heading-large md:display-small">
        Welcome to the OBB Store!
      </h1>
      <h2 class="md:body-large flex justify-center items-center max-w-[600px]">
        Oh Baby, it's time for a OBB shopping spree! Treat yourself or buy gifts for friends. After your purchase, simply refresh the game to see your items.
      </h2>
    </div>
  </div>
</template>

<style>
.img-bg {
  background: url("@/assets/images/homepage/header_icons.png") no-repeat,
    var(
      --gradient-default-secondary,
      linear-gradient(180deg, #a855f7 0%, #6609be 100%)
    );
  background-size: 100% 100%;
}

h1,
h2 {
  color: var(--text-icon-primary-white, #efedf2);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
}

@media screen and (max-width: 1023px) {
  .content {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 1015px) {
  .img-bg {
    background: linear-gradient(180deg, #a855f7 0%, #6609be 100%);
  }
}

@media screen and (max-width: 619px) {
  h1,
  h2 {
    padding: 0 27px;
    margin: auto;
  }
}
</style>
