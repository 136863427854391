<template>
  <button
    class="secondary-large button-large inline-flex items-center justify-center rounded-lg cursor-pointer"
    :class="[
      chipSizeOuter,
      isFocused ? 'bg-[#FA2E5E]' : 'bg-[#322C3F] hover:bg-[#483F5A]',
    ]"
    v-if="label.length >= 1"
  >
    <span
      class="button-large font-['Rubik']"
      :class="[chipSizeLabel, isFocused ? 'text-[#EFEDF2]' : 'text-gray-100']"
      >{{ label }}</span
    >

    <img v-if="rightIcon" src="@/assets/icons/edit.svg" class="relative cursor-pointer" :class="chipSizeIcon" alt="edit-icon" />
  </button>
</template>

<script>
export default {
  props: {
    label: String,
    size: {
      type: String,
      default: "small", // Default to small chip if none defined
    },
    leftIcon: Boolean,
    rightIcon: Boolean,
    isFocused: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    chipSizeOuter() {
      return {
        "h-12 px-4 py-3 gap-2": this.size === "large",
        "h-8 px-2 py-2 gap-1.5": this.size === "small",
        "h-5 px-2 py-1 gap-1": this.size === "xsmall",
      };
    },
    chipSizeLabel() {
      return {
        "text-base leading-normal": this.size === "large",
        "text-xs leading-3": this.size === "small" || this.size === "xsmall",
      };
    },
    chipSizeIcon() {
      return {
        "w-5 h-5": this.size === "large",
        "w-4 h-4": this.size === "small" || this.size === "xsmall",
      };
    },
  },
};
</script>
