<template>
  <div class="modal" v-if="showModal">
      <MainModal 
      :close="false" 
      :type="'lock'" 
      :size="'large'" 
      :showModal="showModal" 
      :button1="true" 
      :button2="true" 
      :button1Click="under18"
      :button2Click="over18"
      @close="showModal = false"
      @over18="over18">
        <template v-slot:title>
          <p class="modal-title">Are you 18 or older?</p>
        </template>
        <template v-slot:message>
          <div v-if="error" class="error">{{ error }}</div>
          <div class="flex flex-col items-center justify-center mb-6">
            <img src="@/assets/images/stickers/sticker_13.png" alt="Stumped Sticker">
          </div>
          <p class="text-base leading-6">We need to verify that you are at least 18 years old. 
            By entering this site you agree to the 
            <a href="https://app.termly.io/document/terms-of-service/b7fd45f7-987b-4f42-9a92-7ac82fb8b85f" target="_blank">Terms of Use</a>
            and 
            <a href="https://app.termly.io/document/privacy-policy/de385d3a-38ed-42be-85b5-24abc4a5ae3e" target="_blank">Privacy Policy</a>.</p>
        </template>
        <template v-slot:button1>
          Exit
        </template>
        <template v-slot:button2>
          I'm over 18
        </template>
      </MainModal>
  </div>
</template>

<script>
import MainModal from './MainModal.vue';

export default {
  components: {
    MainModal,
  },

  data() {
    return {
      error: null,
      showModal: true,

      modalTitle: 'Title',
      leftButton: 'Button1',
      rightButton: 'Button2',
    };
  },

  methods: {
    over18() {
      this.error = null;
      localStorage.setItem('over18', true)
      this.showModal = false;
      this.$emit('age-verification-saved');
    },
    under18() {
      const redirect = "https://ohbabygames.com/"
      window.location.assign(redirect)
    }
  },
};
</script>

<style scoped>
@media screen and (max-width: 400px) {
  .modal-title {
    font-size: 20px;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
input::placeholder {
  opacity: 0.93;
}
p>a {
  color: #A855F7;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
</style>
